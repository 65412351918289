import { createBrowserRouter } from "react-router-dom";
import { SeriesViewer } from "../pages/SeriesViewer";
import { Main } from "../pages/Main";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/linker/:linkerId/series/:seriesId",
    element: <SeriesViewer />,
  },
]);
