export const MutedIcon = () => (
  <svg width={25} height={25} fill="none" viewBox="-0.5 0 25 25">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.94 17.72c2 1.78 4.45 3 5.61 2.61 2.1-.78 2.45-5 2.45-7.92 0-.81 0-1.73-.11-2.64M18.13 6.28a3 3 0 0 0-1.58-1.78c-1.23-.46-3.84 1-6 2.91h-1.6a4 4 0 0 0-4 4v2a4 4 0 0 0 2.36 3.65M22 2.42l-20 20"
    />
  </svg>
);
