export const SoundIcon = () => (
  <svg width={25} height={25} fill="none" viewBox="-0.5 0 25 25">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.55 4.5c-1.23-.46-3.84 1-6 2.91h-1.6a4 4 0 0 0-4 4v2a4 4 0 0 0 4 4h1.6c2.11 1.94 4.72 3.37 6 2.92 2.1-.78 2.45-5 2.45-7.92s-.35-7.13-2.45-7.91ZM20.66 6.72a8 8 0 0 1 0 11.31M18.54 15.95a5 5 0 0 0 0-7.07"
    />
  </svg>
);
