import { Box, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { PlayIcon } from "../assets/icons/play";

type VideoComponentProps = {
  videoSrc: string;
  isFocused?: boolean;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
  isAutoPlay: boolean;
  setIsAutoPlay: (isAutoPlay: boolean) => void;
};

export const VideoComponent = ({
  videoSrc,
  isFocused,
  isMuted,
  isAutoPlay,
  setIsAutoPlay,
}: VideoComponentProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isFocused && videoRef.current && isAutoPlay) {
      videoRef.current?.play();
      setIsPlaying(true);
    }
    if (!isFocused && videoRef.current) {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  }, [videoRef, isFocused, isAutoPlay]);

  const onVideoClick = useCallback(() => {
    if (videoRef.current) {
      setIsAutoPlay(true);
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [videoRef, setIsAutoPlay]);

  return (
    <VStack
      mx="auto"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      onClick={onVideoClick}
    >
      {!isPlaying && (
        <Box
          position="absolute"
          zIndex="2"
          p="4"
          backgroundColor="rgba(255,255,255,0.6)"
          rounded="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PlayIcon />
        </Box>
      )}
      <video
        ref={videoRef}
        muted={isMuted}
        loop
        preload="auto"
        controls={false}
        autoPlay={isAutoPlay}
        controlsList="nofullscreen nodownload noremoteplayback"
        playsInline
        style={{
          height: "100vh",
          display: "block",
          objectFit: "cover",
        }}
      >
        <source src={videoSrc + "#t=0.1"} />
      </video>
    </VStack>
  );
};
