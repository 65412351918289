import { Container, Image } from "@chakra-ui/react";
import logoSquare from "../assets/images/logo_square.png";
import { Link } from "react-router-dom";
import { APP_STORE_URL } from "./SeriesViewer";

export const Main = () => {
  return (
    <Container
      bg="#BEF264"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Link to={APP_STORE_URL}>
        <Image
          src={logoSquare}
          alt="logo"
          borderRadius="3xl"
          style={{ width: "20rem" }}
          shadow="2xl"
        />
      </Link>
    </Container>
  );
};
